<template>
    <span style="display: flex;">
        <span v-html="labelText" dir=auto style="min-width: 0"></span>
        <span v-if="hasHelpText" style="margin-left: 0.2em">
            <div class="tooltip">
                <v-icon
                    dense
                    color="#737373"
                    dark
                >info</v-icon>
                <span class="tooltipcontent">
                    <span v-html="helpText" dir=auto></span>
                </span>
            </div>
        </span>
    </span>
</template>

<script>

import { mapMutations } from 'vuex' 
import { getTranslatedText } from './Translation'

export default {
    name: 'TranslatedText',
    props: {
        labels: Array,
        default: String,
        removeParagraphs: Boolean,
        labelType: {
            type: String,
            default: 'Default'
        }
    },
    methods: {
        ...mapMutations(['showModal']),
        interceptLink: function(domElement) {
            // Look for all of the anchor tags in this component, and attach a custom click handler to each one.
            if (domElement.tagName === 'A') {
                // We found an anchor tag, attach our click handler to it
                domElement.onclick = this.handleLinkClicked;
            }
            else if (domElement.children) {
                // We need to search recursively since there may be anchor tags deep in the node tree
                for (const childNode of domElement.children) {
                    this.interceptLink(childNode);
                }
            }
        },
        handleLinkClicked: function(event) {
            if (event.currentTarget && event.currentTarget.href && event.currentTarget.href.includes('popup:')) {
                // This is a popup, so cancel the standard browser navigation
                event.preventDefault(); 
                let href = event.currentTarget.href;
                
                // Popup links take the form of: https://popup:MyPopupName/ so they will be regarded as real links by the SF rich text editor
                // So we need to strip out all of the other stuff to get the actual popup name
                let popupName = href.replace(/http(s*):\/\/popup:/, '').replace('/', '');

                this.showModal({
                    modalName: popupName
                });
            }
        }
    },
    mounted: function() {
        this.interceptLink(this.$el);
    },
    beforeUpdate: function() {
        this.interceptLink(this.$el);
    },
    computed: {
        labelText() {
            let labels = this.labels ?? [];
            labels = labels.filter(p => p.labelType == this.labelType || !p.labelType);
            return getTranslatedText(labels, this.default, this.removeParagraphs);
        },
        helpText() {
            let labels = this.labels ?? [];
            labels = labels.filter(p => p.labelType == 'Help Text');
            return getTranslatedText(labels, this.default, this.removeParagraphs);
        },
        hasHelpText() {
            if (this.labels) {
                for (const label of this.labels){
                    if (label.labelType==='Help Text'){
                        return true;
                    }
                }
            }
            
            return false;
        }
    }

}
</script>

<style>
    /* icon wrapper */
    .tooltip {
        position: relative;
        display: inline-block;
    }

    /* Tooltip text */
    .tooltip .tooltipcontent {
        visibility: hidden;
        width: 240px;

        background-color: #737373;
        color: #fff;
        text-align: left;
        padding: 5px 5px 5px 5px;
        border-radius: 5px;

        position: absolute;
        z-index: 1;
        bottom: 125%;
        left: 50%;
        margin-left: -120px;
        
        /* Fade in tooltip */
        opacity: 0;
        transition: opacity 0.3s;
    }

    /* bottom nubbin/arrow to allow mouse "bridge" up to tooltip content,
    pure CSS doesn't allow a "delay" on tooltip un-focus*/
    .tooltip .tooltipcontent::after {
        content: "";
        position: absolute;
        top: 100%;
        left: 50%;
        margin-left: -5px;
        border-width: 5px;
        border-style: solid;
        color: #737373;
        border-color: #737373 transparent transparent transparent;
    }

    /* Show the tooltip text when you mouse over the tooltip container */
    .tooltip:hover .tooltipcontent {
        visibility: visible;
        opacity: 1;
    }
</style>