import Vue from 'vue'
import App from './App.vue'
// import router from './router'
import store from './store/index'

import * as Sentry from '@sentry/browser'
import { Vue as VueIntegration } from '@sentry/integrations'

Sentry.init({
    dsn: process.env.VUE_APP_SENTRY_DSN,
    integrations: [new VueIntegration({Vue, attachProps: true, logErrors: true})],
})

// UI Frameworks
import vuetify from '@/plugins/vuetify'

// Globally registered components that are used frequently, don't need to be imported
import TranslatedText from './components/translation/TranslatedText'
import GlobalLabel from './components/translation/GlobalLabel'
Vue.component('TranslatedText', TranslatedText)
Vue.component('GlobalLabel', GlobalLabel)

// Custom element framework
import 'document-register-element/build/document-register-element'
import vueCustomElement from 'vue-custom-element'
Vue.use(vueCustomElement)

import VueMask from 'v-mask'
Vue.use(VueMask);

Vue.config.productionTip = false


// App.router = router

App.store = store

new Vue({
    vuetify,    
    render: h => h(App),
}).$mount('#app')

//Vue.customElement('vue-widget', App)