<template>
    <div>
        <v-container fluid>
            <v-row>
                <v-col>
                    <template v-if="isntFirst">
                        <!-- <v-btn x-large light color="primary" v-on:click="onPrev"><GlobalLabel label-type="Previous Page" default="Previous" /></v-btn> -->
                    </template>
                </v-col>
                <v-col style="display: flex; justify-content: flex-end">
                    <template v-if="isntLast">
                            <v-btn light x-large color="primary" v-on:click="onNext"><GlobalLabel label-type="Next Page" default="Next" /></v-btn>
                    </template>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    name: 'NavigationButtons',
    props: {
        pageIndex: Object,
        numberOfPages: Object
    },
    methods: {
        onPrev: function(){
            //launch event up to page to go back
            this.$emit('navigation', "prev");
        },
        onNext: function(){
            //launch event up to page to go forward
            this.$emit('navigation', "next");
        }
    },
    computed: {
        ...mapGetters(['isLastPage', 'isFirstPage']),
        isntLast: function (){
            return !this.isLastPage;
        },
        isntFirst: function (){
            return !this.isFirstPage;
        }
    }
}
</script>
