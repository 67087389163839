<template>
    <div v-if="rendered" class="selected-site-section">
        <div v-if="isVirtual">
            <v-container>
                <v-row v-if="!hideName" no-gutters>
                    <v-col>{{selectedSiteRecord.name}}</v-col>
                </v-row>
                <v-row v-if="!hidePI" no-gutters>
                    <v-col>{{selectedSiteRecord.chiefResearcher}}</v-col>
                </v-row>
                <v-row v-if="!hidePhone" no-gutters>
                    <v-col>{{selectedSiteRecord.phoneNumber}}</v-col>
                </v-row>
            </v-container>
        </div>

        <div v-if="!isVirtual">
            <v-container>
                <v-row v-if="!hideName" no-gutters>
                    <v-col cols="2"><b><GlobalLabel label-type="Selected Site Name" default="Name:" /></b></v-col>
                    <v-col>{{selectedSiteRecord.name}}</v-col>
                </v-row>
                <v-row v-if="!hideStreet" no-gutters>
                    <v-col cols="2"><b><GlobalLabel label-type="Selected Site Street" default="Street Address:" /></b></v-col>
                <v-col> {{selectedSiteRecord.address}}</v-col>
                </v-row>
                <v-row v-if="!hideCity" no-gutters>
                    <v-col cols="2"><b><GlobalLabel label-type="Selected Site City" default="City:" /></b></v-col>
                    <v-col>{{selectedSiteRecord.city}}</v-col>
                </v-row>
                <v-row v-if="!hideStateProvince" no-gutters>
                    <v-col cols="2"><b><GlobalLabel label-type="Selected Site StateProvince" default="State/Province:" /></b></v-col>
                    <v-col>{{selectedSiteRecord.state}}</v-col>
                </v-row>
                <v-row v-if="!hidePostal" no-gutters>
                    <v-col cols="2"><b><GlobalLabel label-type="Selected Site Postal" default="ZIP/Postal Code:" /></b></v-col>
                    <v-col>{{selectedSiteRecord.postalCode}}</v-col>
                </v-row>
                <v-row v-if="!hidePI" no-gutters>
                    <v-col cols="2"><b><GlobalLabel label-type="Selected Site PI" default="Principal Investigator:" /></b></v-col>
                    <v-col>{{selectedSiteRecord.chiefResearcher}}</v-col>
                </v-row>
                <v-row v-if="!hidePhone" no-gutters>
                    <v-col cols="2"><b><GlobalLabel label-type="Selected Site Phone" default="Phone:" /></b></v-col>
                    <v-col>{{selectedSiteRecord.phoneNumber}}</v-col>
                </v-row>
            </v-container>
        </div>
    </div>
</template>

<script>

import { shouldRender } from '../../ComponentUtils';
import { mapGetters } from 'vuex'

const SELECTED_SITE_NAME_VALUE = 'Hide Selected Site Name Value';
const SELECTED_SITE_STREET_VALUE = 'Hide Selected Site Street Value';
const SELECTED_SITE_CITY_VALUE = 'Hide Selected Site City Value';
const SELECTED_SITE_STATEPROVINCE_VALUE = 'Hide Selected Site StateProvince Value';
const SELECTED_SITE_POSTAL_VALUE = 'Hide Selected Site Postal Value';
const SELECTED_SITE_PI_VALUE = 'Hide Selected Site PI Value';
const SELECTED_SITE_PHONE_VALUE = 'Hide Selected Site Phone Value';

export default {
    name: 'SelectedSiteDetails',
    props: {
        element: Object
    },
    computed: {
        ...mapGetters(["selectedSiteRecord"]),
        rendered() {
            return shouldRender(this.element);
        },
        isVirtual() {
            return this.selectedSiteRecord && this.selectedSiteRecord.virtualSite;
        },
        hideName() {
            if (this.element.configurationFlag && this.element.configurationFlag.includes(SELECTED_SITE_NAME_VALUE)) {
                return true;
            }
            return false;
        },
        hideStreet() {
            if (this.element.configurationFlag && this.element.configurationFlag.includes(SELECTED_SITE_STREET_VALUE)) {
                return true;
            }
            return false;
        },
        hideCity() {
            if (this.element.configurationFlag && this.element.configurationFlag.includes(SELECTED_SITE_CITY_VALUE)) {
                return true;
            }
            return false;
        },
        hideStateProvince() {
            if (this.element.configurationFlag && this.element.configurationFlag.includes(SELECTED_SITE_STATEPROVINCE_VALUE)) {
                return true;
            }
            return false;
        },
        hidePostal() {
            if (this.element.configurationFlag && this.element.configurationFlag.includes(SELECTED_SITE_POSTAL_VALUE)) {
                return true;
            }
            return false;
        },
        hidePI() {
            if (this.element.configurationFlag && this.element.configurationFlag.includes(SELECTED_SITE_PI_VALUE)) {
                return true;
            }
            return false;
        },
        hidePhone() {
            if (this.element.configurationFlag && this.element.configurationFlag.includes(SELECTED_SITE_PHONE_VALUE)) {
                return true;
            }
            return false;
        }

    }
}
</script>

<style scoped>

</style>
