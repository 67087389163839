<template>
    <div v-if="rendered" class="screener-question">
        <div class="screener-question-label">
            <template v-if="element.required">
                <div class="required-marker">*</div>
            </template>
            <TranslatedText :labels="element.labels" :default="element.name"  />
        </div>
        <div class="screener-question-input">
            <template v-if="element.questionType === 'Text'">
                <TextQuestion :element="element" :current-value="currentValue" />
            </template>
            <template v-if="element.questionType === 'Checkbox'">
                <CheckboxQuestion :element="element" :current-value="currentValue" />
            </template>
            <template v-if="element.questionType === 'CheckboxGroup'">
                <CheckboxGroupQuestion :element="element" :current-value="currentValue" />
            </template>
            <template v-if="element.questionType === 'Radio'">
                <RadioQuestion :element="element" :current-value="currentValue" />
            </template>
            <template v-if="element.questionType === 'TableRadio'">
                <RadioGroupQuestion :element="element" /> <!-- Intentionally not passing currentvalue as it is a compound question type -->
            </template>
            <template v-if="element.questionType === 'Picklist'">
                <PicklistQuestion :element="element" :current-value="currentValue"  />
            </template>
            <template v-if="element.questionType === 'DOBMonth'">
                <BirthdateQuestion :element="element" :current-value="currentValue" />
            </template>
            <template v-if="element.questionType === 'Date'">
                <DateQuestion :element="element" :current-value="currentValue" />
            </template>
            <template v-if="element.questionType === 'Number'">
                <NumberQuestion :element="element" :current-value="currentValue" />
            </template>
        </div>
        <div class="screener-question-error" v-if="hasValidationError">
            <template v-if="hasCustomValidationMessages">
                <TranslatedText label-type="Validation Message" :labels="validationMessageLabels" default="* Required" />
            </template>
            <template v-if="!hasCustomValidationMessages">
                <GlobalLabel label-type="Field Validation Error" default="* Required" />
            </template>
        </div>
    </div>
</template>

<script>
 /* eslint-disable */

import { mapGetters } from 'vuex'

import CheckboxQuestion from './CheckboxQuestion';
import TextQuestion from './TextQuestion';
import DateQuestion from './DateQuestion';
import CheckboxGroupQuestion from './CheckboxGroupQuestion';
import RadioQuestion from './RadioQuestion';
import RadioGroupQuestion from './RadioGroupQuestion';
import PicklistQuestion from './PicklistQuestion';
import BirthdateQuestion from './BirthdateQuestion';
import NumberQuestion from './NumberQuestion';

import { shouldRender } from '../../ComponentUtils';

export default {
    name: 'Question',
    components: {
        CheckboxQuestion,
        TextQuestion,
        DateQuestion,
        BirthdateQuestion,
        CheckboxGroupQuestion,
        RadioQuestion,
        RadioGroupQuestion,
        PicklistQuestion,
        NumberQuestion
    },
    props: {
        element: Object
    },
    computed: {
        ...mapGetters(["responses", "responsesByVariableName", "validationErrors"]),
        rendered() {
            return shouldRender(this.element);
        },
        hasCustomValidationMessages() {
            return this.validationMessageLabels.length > 0;
        },
        currentValue() {
            let value = null;
            if (this.element.sfid in this.responses) {
                value = this.responses[this.element.sfid].value;

            }

            return value;
        },
        hasValidationError() {
            if (this.validationErrors && this.validationErrors.length > 0 && this.validationErrors.includes(this.element.sfid)) {
                return true;
            }

            return false;
        },
        validationMessageLabels() {
            if (this.element.labels)  {
                return this.element.labels.filter(p => p.labelType == 'Validation Message');
            }

            return [];
        }
    }
}
</script>

<style>
    .screener-question-label {
        display: flex;
        margin-bottom: 0.4em;
    }
    .required-marker {
        font-weight: bold;
        margin-right: 0.5rem;
        color: red;
    }
    .screener-question-label p {
        margin-bottom: 0px !important;
    }
    .screener-question-error {
        color: red;
    }
    .screener-question-label IMG {
        width: 100%;
    }
</style>
