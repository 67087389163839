<template>
    <v-card v-if="rendered" class="screener-card mx-auto" raised>
        <v-card-title v-if="cardElement.labels && cardElement.labels.length > 0">
            <div class="md-title">
                <TranslatedText :labels="cardElement.labels" :default="cardElement.name"  />
            </div>
        </v-card-title>
        <v-card-text class="text--primary">
            <div v-for="element in cardElement.children" :key="element.name">
                <div v-if="element.type === 'Display'" class="screener-card-item">
                    <Label :element="element" />
                </div>
                <div v-if="element.type === 'Question'" class="screener-card-item">
                    <Question :element="element" />
                </div>
                <div v-if="element.type === 'Site Selection'" class="screener-card-item">
                    <Map :element="element" ></Map>
                </div>
                <div v-if="element.type === 'Selected Site Details'" class="screener-card-item">
                    <SelectedSiteDetails :element="element" ></SelectedSiteDetails>
                </div>
            </div>
        </v-card-text>
    </v-card>
</template>


<script>
 /* eslint-disable */

import Label from '../display/Label'
import Question from '../question/Question'
import SelectedSiteDetails from '../display/SelectedSiteDetails'
import Map from '../question/Map'

import { shouldRender } from '../../ComponentUtils';
import { mapActions } from 'vuex';

export default {
    name: 'Card',
    components: {
        Label,
        Question,
        Map,
        SelectedSiteDetails
    },
    props: {
        cardElement: Object
    },
    methods: {
        ...mapActions(['updateLeadStatus'])
    },
    computed: {
        rendered() {
            let result = shouldRender(this.cardElement);

            if (result && this.cardElement.variableName) {
                let leadStatus = this.cardElement.variableName;
                this.updateLeadStatus(leadStatus);
            }

            return result;
        }
    }
}
</script>

<style scoped>
    .screener-card {
        margin: 1em;
    }
    .screener-card-item {
        margin-top: 1em;
        margin-bottom: 1em;
    }
</style>
