<template>
    <span v-html="labelText"></span>
</template>

<script>

import { mapGetters } from 'vuex' 
import { getTranslatedText } from './Translation'

export default {
    name: 'GlobalLabel',
    props: {
        labelType: String,
        default: String,
        removeParagraphs: {
            type: Boolean,
            default: true
        }
    },
    computed: {
        ...mapGetters(['globalLabels']),
        labelText() {
            let labels = this.globalLabels.filter(p => p.globalLabelType === this.labelType);
            return getTranslatedText(labels, this.default, this.removeParagraphs);
        },
    }
}
</script>