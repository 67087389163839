<template>
    <div class="screener-question-checkboxgroup">
        <template v-for="item in checkboxOptions" >
            <v-checkbox v-model="selected" :value=item.value v-on:change="onChange()" :key="item.value" hide-details>
                <template v-slot:label>
                    <span v-html="item.label"></span>
                </template>
            </v-checkbox>
        </template>

        <div v-if="hasOther" class="otherText">
            <v-text-field outlined v-model="otherVal" v-on:blur="onChange()"></v-text-field>
        </div>
    </div>
</template>

<script>
import { mapActions } from 'vuex'
import { getTranslatedText } from '../../translation/Translation';

export default {
    name: 'CheckboxGroupQuestion',
    props: {
        element: Object,
        currentValue: String,
    },
    data: function() {
        return {
            checkVal: null,
            selected: [],
            otherVal: null
        }
    },
    mounted: function() {
        if (this.currentValue) {
            this.selected = this.currentValue.split(',');
        }
    },
    computed: {
        checkboxOptions: function() {
            let result = [];

            if (this.element && this.element.options) {
                for (const option of this.element.options) {
                    result.push({
                        value: option.value,
                        label: getTranslatedText(option.labels, option.name, true),
                        sequence: option.sequence
                    })
                }
            }

            result.sort((a,b) => 
                (a.sequence && b.sequence) ? a.sequence - b.sequence : 0
            )

            return result;
        },
        hasOther: function(){
            
            for(var i = 0; i<this.selected.length; i++){
                if(this.selected[i].toUpperCase()==="OTHER"){
                    return true;
                }
            }
            return false;
        }
    },
    methods: {
        ...mapActions([
            'saveResponse'
        ]),
        onChange: function() {

            if(this.hasOther && this.otherVal!==null){
                let res = [...this.selected];

                for (let i = 0; i < res.length; i++) {
                    let val = res[i];
                    if(val && val.toUpperCase() == "OTHER"){
                        res[i] = 'Other/' + this.otherVal;
                    }
                }

                this.saveResponse({
                    variableName: this.element.variableName,
                    elementId: this.element.sfid,
                    newValue: res.join()
                });
            }
            else {
                this.otherVal=null
                this.saveResponse({
                    variableName: this.element.variableName,
                    elementId: this.element.sfid,
                    newValue: this.selected.join()
                });
            }
        }
    }
}
</script>

<style scoped>
.v-input--selection-controls {
    margin-top: 0 !important;
    padding-top: 0 !important;
}
.otherText {
    margin-top: 0.3em;
}
</style>