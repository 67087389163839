<template>
    <div v-if="rendered">
        <TranslatedText :labels="element.labels" :default="element.name" />
    </div>
</template>

<script>
 /* eslint-disable */

import { shouldRender } from '../../ComponentUtils';

export default {
    name: 'Label',
    props: {
        element: Object
    },
    computed: {
        rendered() {
            return shouldRender(this.element);
        }
    }
}
</script>

<style scoped>

</style>
