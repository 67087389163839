<template>
    <div class="screener-question-date">
        <v-menu
            ref="menu"
            v-model="menu"
            :close-on-content-click="false"
            :return-value.sync="date"
            transition="scale-transition"
            offset-y
            min-width="290px"
        >
            <template v-slot:activator="scope">
                <v-text-field
                    v-model="dateFormatted"
                    prepend-icon="event"
                    v-on="scope.on"
                    hide-details="auto"
                    persistent-hint
                    readonly
                ></v-text-field>
            </template>
            <v-date-picker v-model="date" no-title scrollable @input="menu = false">
            </v-date-picker>
        </v-menu>
    </div>
</template>

<script>

import { mapActions } from 'vuex'

export default {
    name: 'DateQuestion',
    props: {
        element: Object,
        currentValue: String
    },
    data: function() {
        return {
            date: null,
            dateFormatted: null,
            menu: false,
            country: null
        }
    },
    mounted: function() {
        if (this.currentValue) this.date = this.currentValue;
        this.country = this.$store.getters.country;
    },
    watch: {
        date: function(newValue) {
            //update text field 
            //format validation
            if (this.validateFormat(newValue)){
                this.dateFormatted = this.formatDate(this.date);
                this.saveResponse({
                    variableName: this.element.variableName,
                    elementId: this.element.sfid,
                    newValue: newValue
                });
            }
        },
    },
    computed: {
        computedDateFormatted () {
            return this.formatDate(this.date)
        },
    },
    methods: {
        ...mapActions([
            'saveResponse'
        ]),
        validateFormat: function(date){
            if(date){
                var regEx = /^\d{4}-\d{2}-\d{2}$/;
                if(!date.match(regEx)) return false;  // Invalid format
                var d = new Date(date);
                var dNum = d.getTime();
                if(!dNum && dNum !== 0) return false; // NaN value, Invalid date
                return d.toISOString().slice(0,10) === date;
            }
        },
        formatDate (date) {
            if (!date) return null

            const [year, month, day] = date.split('-')
            return `${month}/${day}/${year}`
        },
        parseDate (date) {
            if (!date) return null
            const [month, day, year] = date.split('/')
            return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
        },
    }
}

</script>

<style scoped>
.v-text-field {
    margin-top: 0 !important;
    padding-top: 0 !important;
}
</style>