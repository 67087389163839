<template>
    <div class="screener-question-checkbox">
        <v-checkbox dense v-model="checkVal" value="true" v-on:change="onChange()" hide-details="auto"></v-checkbox>
    </div>
</template>

<script>
import { mapActions } from 'vuex'


export default {
    name: 'CheckboxQuestion',
    props: {
        element: Object,
        currentValue: String
    },
    data: function() {
        return {
            checkVal: null
        }
    },
    mounted: function() {
        if (this.currentValue) this.checkVal = this.currentValue;
    },
    methods: {
        ...mapActions([
            'saveResponse'
        ]),
        onChange: function() {
            this.saveResponse({
                variableName: this.element.variableName,
                elementId: this.element.sfid,
                newValue: this.checkVal
            });
        }
    }
}
</script>

<style scoped>
.v-input--selection-controls {
    margin-top: 0 !important;
    padding-top: 0 !important;
}
</style>
