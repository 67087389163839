<template>
    <div v-if="rendered" class="map-element">
        <!-- <v-layout wrap> -->
        <!-- <v-container grid-list-xl fluid> -->
            <v-row wrap column>
                <v-col xs="12" sm="12" md="6" lg="6" xl="6" cols={colSize}>
                    <template v-if="!siteSelectionZip">
                        <v-row>
                            <v-col>
                                <GlobalLabel
                                        label-type="Map Search Prompt"
                                        default="To match you to the nearest study site, please enter your ZIP or postal code:" />
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col style="width: 100px" cols="12">
                                <div style="display: flex; align-items: center">
                                <v-text-field
                                    :label="searchPlaceholder"
                                    v-model="search"
                                    @keydown.enter="onSearch"
                                    outlined
                                    clearable
                                    persistent-hint
                                    hide-details="auto"
                                ></v-text-field>
                                <v-btn class="screenerSearchButton" light medium color="primary" v-on:click="onSearch" height="3rem">
                                    <v-icon left dark>mdi-magnify</v-icon>
                                    <GlobalLabel label-type="Search" default="Search" />
                                </v-btn>
                                </div>
                            </v-col>
                        </v-row>
                    </template>

                    <v-row>
                        <div v-if="invalidSearch" style="margin: 1em; text-align: center; width: 100%" class="screener-question-error">
                            <GlobalLabel
                                    label-type="Map Invalid Search"
                                    default="Please enter a valid ZIP or Postal Code" />
                        </div>
                        <div v-if="hasSites && !autoAssign" style="margin: 1em">
                            <b>
                                <GlobalLabel
                                    label-type="Map Select Hint"
                                    default="The following research site(s) are participating in this study, please
                                    select the most convenient location for you" />
                            </b>
                        </div>
                    </v-row>
                    <v-row>
                        <gmap-map
                            ref="mapRef"
                            style="width:100%;  height: 400px;"
                            :center="{lat:currentCenter.lat, lng:currentCenter.lng}"
                        >
                            <gmap-marker
                                :key="index"
                                v-for="(m, index) in markers"
                                :position="m.position"
                                v-on:click="handleMarker($event, m.sfid)"
                                :clickable="true"
                                :icon="m.icon"
                            ></gmap-marker>
                        </gmap-map>
                    </v-row>
                </v-col>
                <v-col xs="12" sm="12" md="6" lg="6" xl="6" cols={colSize}>
                        <div v-if="searchCompleted && dataLoaded" >
                            <div v-if="hasSites"  style="max-height: 600px;" class="overflow-y-auto">
                                <div v-if="!autoAssign">

                                    <v-radio-group
                                        v-model="selectedSite"
                                        v-on:change="handleSave">
                                        <div v-for="site in sites" :key="site.protocolSiteId" id="scrollParent" v-scroll:#scroll-target="onScroll" style="margin-bottom: 1rem">
                                            <!-- separate site component -->
                                            <SiteCard :site="site"/>
                                        </div>
                                        <v-card>
                                            <v-container>
                                                <v-row>
                                                    <v-col>
                                                        <div class="none-header">
                                                            <GlobalLabel
                                                                label-type="Map None Header"
                                                                default="None Of The Above" />
                                                            <!-- None Of The Above -->
                                                        </div>
                                                        <div class="none-body">
                                                            <GlobalLabel
                                                                label-type="Map None Body"
                                                                default="None of these sites are convenient for me." />
                                                            <!-- None of these sites are convenient for me. -->
                                                        </div>
                                                    </v-col>
                                                    <v-col align-self="center" cols="2">
                                                        <v-radio value="no-convenient" ripple prepend-icon></v-radio>
                                                    </v-col>
                                                </v-row>
                                            </v-container>

                                        </v-card>
                                    </v-radio-group>
                                </div>
                                <div v-else>
                                    <div class="auto-assign-message">
                                        <GlobalLabel
                                            label-type="Map Auto Assign"
                                            default="We have found a research site participating in this study. Please click &quot;Next&quot; to continue" />
                                        <!-- We have found a research site participating in this study. Please click "Next" to continue -->
                                    </div>
                                </div>
                            </div>
                            <div v-if="!hasSites">
                                <div class="no-sites-message">
                                    <GlobalLabel
                                        label-type="Map No Results"
                                        default="Sorry, we did not find any participating sites in your area.
                                        Please search on a different ZIP/postal code, or click next to continue." />
                                    <!-- Sorry, we did not find any participating sites in your area. Please search on a different ZIP/postal code, or click next to continue. -->
                                </div>
                            </div>
                            <div class="screener-question-error" v-if="hasValidationError" style="margin: 1rem">
                                <GlobalLabel
                                    label-type="Map Validation 2"
                                    default="Please select an option above to continue" />
                            </div>
                        </div>
                        <!-- If data not prepped/here yet -->
                        <div v-else style="text-align: center; ">
                            <b>
                                <GlobalLabel
                                    label-type="Map Search Hint"
                                    default="Please enter your ZIP/Postal Code for available options" />
                                <!-- Please enter your ZIP/Postal Code for available options -->
                            </b>

                            <div class="screener-question-error" v-if="hasValidationError" style="margin: 1rem">
                                <GlobalLabel
                                    label-type="Map Validation 1"
                                    default="Please enter a ZIP/Postal Code and click 'Search' to search for sites before continuing" />
                            </div>
                        </div>
                    <!-- </v-container> -->
                </v-col>
            </v-row>
    </div>
</template>

<script>
 /* eslint-disable */

import { mapGetters, mapMutations } from 'vuex';
import { shouldRender } from '../../ComponentUtils';
import Vue from "vue";
import * as VueGoogleMaps from 'vue2-google-maps';
import { mapActions } from 'vuex'
import { getSites } from '../../../network/api';
import SiteCard from './SiteCard';
import GlobalLabel from '../../translation/GlobalLabel';
import { getGlobalLabel } from '../../translation/Translation';

import { gmapApi } from 'vue2-google-maps'

const GOOGLE_API_KEY = process.env.VUE_APP_GOOGLE_API;


Vue.use(VueGoogleMaps, {
    load: {
        key: GOOGLE_API_KEY //"AIzaSyCZejKoIf1mte6Pa4mmCvYLpj3h9U2dG98",
    }
})

export default {
    name: 'Map',
    components: {
        SiteCard,
        GlobalLabel
    },
    mounted() {

        this.$refs.mapRef.$mapPromise.then((map) => {

            let google = gmapApi();
            let geocoder =  new google.maps.Geocoder();
            let country = this.$store.getters.country;
            geocoder.geocode( { 'address': country}, function(results, status) {
                if (status == 'OK') {
                    map.setCenter(results[0].geometry.location);
                    map.fitBounds(results[0].geometry.viewport);
                } else {
                    console.log('Geocode was not successful for the following reason: ' + status);
                }
            }.bind(this));
        });

        if (this.siteSelectionZip) {
            this.search = this.siteSelectionZip;
            console.log('Auto-firing search!');
            this.onSearch();
        }
    },
    data: function() {
        return {
            dataIn: null,
            search: "",
            searchCompleted: false,
            invalidSearch: false,
            selectedSite: "",
            offsetTop: 0//scroll
        }
    },
    props: {
        element: Object
    },
    computed: {
        ...mapGetters(["validationErrors", "siteSelectionZip"]),
        google: gmapApi,
        rendered() {
            return shouldRender(this.element);
        },
        searchPlaceholder() {
            return getGlobalLabel('Map Search Box', 'Search', true);
        },
        hasSites() {
            return this.dataIn && this.dataIn.sites && this.dataIn.sites.length > 0;
        },
        validationMessageLabels() {
            if (this.element.labels)  {
                return this.element.labels.filter(p => p.labelType == 'Validation Message');
            }

            return [];
        },
        hasValidationError() {
            if (this.validationErrors && this.validationErrors.length > 0 && this.validationErrors.includes(this.element.sfid)) {
                return true;
            }

            return false;
        },
        sites() {
            if (this.dataLoaded) {
                var sites = this.dataIn.sites;
                const maxSites = this.dataIn.maxSites;

                //Moved to this system to handle more than 2 duplicate addr sites
                var seenSites = [];
                //2D array of sites of same addr
                var splitSites = [];
                //break sites into individual arrays based on address
                for(var x = 0; x < sites.length; x++){
                    //if address hasn't already been seen, grab
                    if(!seenSites.includes(sites[x].siteId)){
                        var sitesWithAddr = []

                        //grab all sites with same addr
                        for(var y = x; y < sites.length; y++){
                            //if remaining site(s) have addr, push to correct array

                            if(sites[y].siteId === sites[x].siteId){
                                sitesWithAddr.push(sites[y])
                            }
                        }
                        //push sites of same addr array onto master array
                        splitSites.push(sitesWithAddr)
                    }
                    //add addr to seen addressses
                    seenSites.push(sites[x].siteId)
                }

                //pick site w/ top ranked protocol from each sites of same addr array
                var sites2 = []
                for (var z = 0; z < splitSites.length; z++){
                    //loop thru sites of same addr
                    var topSite = null;
                    for (var a = 0; a < splitSites[z].length; a++){
                        let protocol = this.protocols.find(obj => {return obj.sfid === splitSites[z][a].protocol});
                        let currProtocolRank = protocol.protocolRank;
                        //first loop null check
                        if (topSite){
                            //if protocol rank larger than current winner, replace
                            let topProtocol = this.protocols.find(row => row.sfid === topSite.protocol);
                            if (currProtocolRank < topProtocol.protocolRank){
                                topSite = splitSites[z][a];
                            }
                            //if not, current topSite reigns
                        }
                        else {
                            topSite = splitSites[z][a];
                        }
                    }
                    //push topSite to final site array
                    sites2.push(topSite);
                }
                //re-sort sites on distance
                sites = sites2.sort((a, b) => (a.distance > b.distance) ? 1: -1)


                //if auto-assign, return first site only
                if (sites && sites.length > 0) {
                    if(this.autoAssign){
                        sites[0].distance = Math.round((sites[0].distance + Number.EPSILON) * 100) / 100;
                        if (sites[0].languages) {
                            sites[0].languages = (sites[0].languages.split(";")).join(", ");
                        }
                        sites = [sites[0]];
                    }
                    else {
                        if (maxSites && sites.length > maxSites) {
                            sites = sites.slice(0, maxSites);
                        }

                        //pre-processing
                        for(var i = 0; i < sites.length; i++){
                            sites[i].distance = Math.round((sites[i].distance + Number.EPSILON) * 100) / 100;
                            if (sites[i].languages) {
                                sites[i].languages = (sites[i].languages.split(";")).join(", ");
                            }
                        }
                    }
                }

                return sites;

            }
            return null;
        },
        nearestSitesNotInRadius() {
            let result = [];

            if (this.dataIn && this.dataIn.nearestSites) {
                const applicableSites = this.dataIn.nearestSites.filter(row => {
                    let protocol = this.dataIn.protocols.find(p => p.sfid == row.protocol);

                    if (protocol) {
                        return shouldRender(protocol);
                    }

                    return true;
                }).sort((site1, site2) => {
                    let protocol1 = this.dataIn.protocols.find(p => p.sfid == site1.protocol);
                    let protocol2 = this.dataIn.protocols.find(p => p.sfid == site2.protocol);
                    if (protocol1 && protocol2 && protocol1.protocolRank && protocol2.protocolRank) {
                        return protocol1.protocolRank < protocol2.protocolRank ? -1 : 1;
                    }

                    return 0;
                });

                result = applicableSites;
            }

            return result;
        },
        protocols() {
            return this.dataIn.protocols;
        },
        autoAssign() {
            return this.dataIn.autoAssignSites;
        },
        zipLocation() {
            return this.dataIn.postCodeLocation;
        },
        currentCenter() {
            return {lat : 0, lng : 0};
        },
        autoAssignVirtualSite() {
            // When autoAssignVirtualSite is true, only be 1 site will be returned
            return this.dataIn && this.dataIn.autoAssignVirtualSite === true;
        },
        markers() {
            var markers = [];
            if(this.dataLoaded){
                for(var i = 0; i<this.sites.length; i++){
                    const marker = {
                        lat: parseFloat(this.sites[i].latitude),
                        lng: parseFloat(this.sites[i].longitude)
                    };

                    // Place the map marker on the user input zip for autoAssignVirtualSite
                    if (this.autoAssignVirtualSite) {
                        marker.lat = this.zipLocation.lat;
                        marker.lng = this.zipLocation.lng;
                    }

                    //if selected or not (color change)
                    if(this.selectedSite !== this.sites[i].sfid){
                        markers.push({
                            position: marker,
                            id: this.sites[i].sfid,
                            sfid: this.sites[i].sfid,
                            //using google icons
                            icon: {
                                url: "https://maps.google.com/mapfiles/ms/icons/red-dot.png"
                            }
                        });
                    }else{
                        //fix to stop marker color-changing on auto-assigned single site marker
                        if(!this.autoAssign){
                            markers.push({
                                position: marker,
                                id: this.sites[i].sfid,
                                sfid: this.sites[i].sfid,
                                //using google icons
                                icon: {
                                    url: "https://maps.google.com/mapfiles/ms/icons/blue-dot.png"
                                }
                            });
                        }else{
                            markers.push({
                                position: marker,
                                id: this.sites[i].sfid,
                                sfid: this.sites[i].sfid,
                                //using google icons
                                icon: {
                                    url: "https://maps.google.com/mapfiles/ms/icons/red-dot.png"
                                }
                            });
                        }
                    }
                }
            }
            return markers;
        },
        dataLoaded() {
            if(this.dataIn){//&& this.sites.length
                return true;
            }
            return false;
        }
    },
    methods: {
        ...mapActions([
            'saveResponse',
            'triggerSave'
        ]),
        ...mapMutations([
            'removeElementFromValidationErrors',
            'updateSelectedSite',
            'updateVariablesLocal'
        ]),
        onSearch: async function(){
            if(this.search){
                this.invalidSearch = false;

                //search val send to backend
                let screenerId = this.$store.getters.screenerId;
                let country = this.$store.getters.country;
                let protocolGroupId = this.$store.getters.protocolGroupId;

                this.updateVariablesLocal({
                    variableName: 'siteSearchEntry',
                    newValue: this.search
                });

                this.triggerSave();

                try {
                    this.dataIn = await getSites(
                        protocolGroupId,
                        screenerId,
                        this.search,
                        country
                    );

                    this.filterSites();
                    this.logSiteDistance();

                    this.searchCompleted = true;

                    this.setZoomAndPan();

                    if (this.sites.length == 0) {
                        //Nothing found, save "none"
                        this.selectedSite = "none";
                        this.handleSave();
                    }
                    else if (this.sites.length > 0 && this.autoAssign) {
                        this.selectedSite = this.sites[0].sfid;
                        this.handleSave();
                    } else {
                        // Preemptively clear out selectedSite value, to handle instances where user searched for a zip code 
                        //   that doesn't have a site match, then searches again, then clicks "Next" without selecting a site.
                        // This was incorrectly saving the nearestSiteDistance of the newly searched zip code, and still showing 
                        //   true for DNQ_No_Site_In_Area.
                        this.selectedSite = '';
                        this.handleSave();
                    }
                }
                catch (err) {
                    if (err.response) {
                        if (err.response.status == 404) {
                            this.invalidSearch = true;
                        }
                        else {
                            // Some server error while searching, the server logs will log the error and the user should know their request didn't work
                            alert('Sorry! We encountered an unexpected error while searching for sites. Please try again later');
                        }
                    }
                    else {
                        // Some javascript error while searching, we should re-throw so that JS logging can pick it up
                        alert('Sorry! We encountered an unexpected error while searching for sites. Please try again later');
                        throw err;
                    }
                }

                // Clear out the current validation flag for this element if there is one
                // This is to avoid confusion on the user's part where they clicked next without searching,
                // Then entered a ZIP code, we want to clear out the validation error to indicate they are on the right track.
                // The validation flag should be re-set if they try to click next again without selecting a site.
                this.removeElementFromValidationErrors(this.element.sfid);
            }
            else {
                this.invalidSearch = true;
            }
        },
        logSiteDistance: function() {
            // This logs the nearest site distance.
            const sites = this.sites;
            let nearestSiteDistance = undefined;
            let nearestSiteNumber = undefined;

            if (sites && sites.length && sites[0].distance) {
                nearestSiteDistance = sites[0].distance;
                nearestSiteNumber = sites[0].siteNumber;
            }
            else if (this.nearestSitesNotInRadius.length) {
                nearestSiteDistance = this.nearestSitesNotInRadius[0].distance;
                nearestSiteNumber = this.nearestSitesNotInRadius[0].siteNumber;
            }

            if (nearestSiteDistance) {
                this.updateVariablesLocal({
                    variableName: 'nearestSiteDistance',
                    newValue: nearestSiteDistance
                });

                this.updateVariablesLocal({
                    variableName: 'nearestSiteNumber',
                    newValue: nearestSiteNumber
                });

                this.triggerSave();
            }


        },
        filterSites: function() {
            if (this.dataIn && this.dataIn.sites) {
                this.dataIn.sites = this.dataIn.sites.filter(row => {
                    let protocol = this.dataIn.protocols.find(p => p.sfid == row.protocol);

                    if (protocol) {
                        return shouldRender(protocol);
                    }

                    return true;
                });


            }
        },
        setZoomAndPan: function() {
            if(this.hasSites) {
                if (this.sites.length > 1) {
                    let bounds = new this.google.maps.LatLngBounds();

                    for (const site of this.sites) {
                        bounds.extend({lat: site.latitude, lng: site.longitude});
                    }

                    this.$refs.mapRef.$mapPromise.then((map) => {
                        map.fitBounds(bounds, 50);
                    });
                }
                else if (this.autoAssignVirtualSite) {
                    const zipCenter = {lat : this.zipLocation.lat, lng : this.zipLocation.lng};
                    this.$refs.mapRef.$mapPromise.then((map) => {
                        map.setZoom(8);
                        map.setCenter(zipCenter);
                    });
                }
                else {
                    const locationCenter = { lat: this.sites[0].latitude, lng: this.sites[0].longitude };
                    this.$refs.mapRef.$mapPromise.then((map) => {
                        map.setZoom(8);
                        map.setCenter(locationCenter);
                    });
                }
            }
            else if (!this.hasSites && this.searchCompleted) {
                const zipCenter = {lat : this.zipLocation.lat, lng : this.zipLocation.lng};
                this.$refs.mapRef.$mapPromise.then((map) => {
                    map.setZoom(8);
                    map.setCenter(zipCenter);
                });
            }
        },
        handleMarker: function (event, id){
            this.selectedSite = id;
            this.handleSave();
        },
        onScroll(e){
            this.offsetTop = e.target.scrollTop
        },
        //called by handleMarker, radio group onChange
        //id/sfid selected should always be set
        handleSave(){
            var selectedId = this.selectedSite;

            this.setSelectedSiteRecord();

            this.saveResponse({
                variableName: this.element.variableName,
                elementId: this.element.sfid,
                newValue: selectedId//this.selectedSite
            });
        },
        setSelectedSiteRecord() {
            let selectedId = this.selectedSite;
            let allSites = this.dataIn.sites;

            if (selectedId) {
                let selectedSiteRecord = allSites.find(p => p.sfid == selectedId);
                this.updateSelectedSite(selectedSiteRecord);

                if (selectedSiteRecord) {
                    this.updateVariablesLocal({
                        variableName: 'siteDistance',
                        newValue: selectedSiteRecord.distance
                    });

                    this.updateVariablesLocal({
                        variableName: 'siteOrder',
                        newValue: (allSites.findIndex(p => p.sfid == selectedId) + 1)
                    });
                }

            }
            else {
                this.updateSelectedSite(null);
            }

        }
    }
}
</script>

<style>
    .auto-assign-message {
        font-weight: bold;
    }
    .no-sites-message {
        margin: 1rem;
        font-weight: bold;
    }
    .none-header {
        font-weight: bold;
        font-size: 0.8em; /* added to match site-card */
    }
    .none-body {
        color: darkgray;
        font-size: 0.8em;
    }
</style>
