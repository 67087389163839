<template>
  <v-app>
    <v-main>
      <v-container fluid>
        <div id="app" :class="{ 'rtlLanguage': isRtlLanguage }">
          <div v-if="showModal">
            <CtmDialog :dialog-name="currentModalName" />
          </div>
          <div v-if="isLoadingApplication">
            <h1>Loading...</h1>
            <v-progress-circular
              :size="70"
              :width="7"
              color="blue"
              indeterminate
            ></v-progress-circular>
          </div>
          <div v-if="!isLoadingApplication && !errorLoadingScreener && getPages && getPages.length">
            <div v-if="screenerArchived">
              <v-card class="screener-card mx-auto" raised>
                <v-card-title>
                    <div class="md-title">
                      <GlobalLabel
                        label-type="Closed Screener Header"
                        default="Screener Closed" />
                    </div>
                </v-card-title>
                <v-card-text class="text--primary">
                    <GlobalLabel
                      label-type="Closed Screener Body"
                      default="Sorry, this screener has been closed and is no longer available." />
                </v-card-text>
              </v-card>
            </div>
            <div v-else>
              <ScreenerPage :page="getCurrPage" />
            </div>
          </div>
          <div v-else-if="errorLoadingScreener">
              <v-card class="screener-card mx-auto" raised>
                <v-card-title>
                    <div class="md-title">
                      Unable to Load Page
                    </div>
                </v-card-title>
                <v-card-text class="text--primary">
                  <div v-if="notFound">
                    This screener could not be found. Please check the link you clicked on and contact the site administrator if you think this message is in error.
                  </div>
                  <div v-if="internalServerError">
                    We encountered an unexpected system issue while loading this page. Please try again later.
                  </div>
                  <div v-if="clientError">
                    We encountered an unexpected issue while loading this page. Please check your internet connection is active and try again later.
                  </div>

                </v-card-text>
              </v-card>
          </div>
        </div>
        <div v-if="!isLoadingApplication && debugMode && sessionId" style="text-align: center">
          <a :href="debugLink" target="_blank">View Responses</a>
        </div>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>

import { mapGetters, mapState } from 'vuex'
import { API_BASE } from './network/api';
import { loadStyleSheet } from './utils/stylesheets';
import ScreenerPage from './components/elements/layout/ScreenerPage'
import CtmDialog from './components/elements/display/CtmDialog'
// import GlobalLabel from './components/translation/GlobalLabel'
// import Card from './components/elements/display/Card'
import moment from 'moment'

const DEBUG_MODE = process.env.VUE_APP_DEBUG_MODE && process.env.VUE_APP_DEBUG_MODE == 'true';

export default {
  name: 'App',
  components: {
    ScreenerPage,
    CtmDialog,
    // GlobalLabel
  },
  data: function() {
    return {
    }
  },
  mounted: async function() {
    console.log('App was mounted!');
    this.registerCloseHandlers();
    this.parseUrlParameters();
    //store start time
    this.setStartTime();
  },
  methods: {
      registerCloseHandlers: function() {
        window.addEventListener("beforeunload", this.confirmClosing, false);
        window.addEventListener("unload", this.sendBeacon, false);
      },
      confirmClosing: function(e) {
        if (!this.isLastPage) {
          const message = 'Are you sure you want to leave? Any responses you have entered will be lost and you will need to start over.';

          e = e || window.event;
          if (e) {
            e.returnValue = message;
          }

          return message;
        }

      },
      sendBeacon: function() {
        if (!this.isLastPage) {
          let formData = new FormData();
          formData.append('sessionId', this.sessionId);
          formData.append('protocolGroupId', this.protocolGroupId);
          formData.append('event', 'windowClosed');

          navigator.sendBeacon(API_BASE + `/api/v1/screener/beacon`, formData);
        }
      },
      parseUrlParameters: function() {
        const urlParams = new URLSearchParams(window.location.search);
        for (const value of urlParams.entries()) {
          const variableName = value[0];
          const newValue = value[1];

          if (variableName === 'styleUrl') {
            loadStyleSheet(newValue);
          }
          else if (variableName === 'protocolGroupId') {
            this.$store.dispatch('updateProtocolGroupId', newValue);
          }
          else {
            this.$store.commit('updateVariablesLocal', {
              variableName: variableName,
              newValue: newValue
            })
          }
        }

        this.$store.commit('updateVariablesLocal', {
            variableName: 'REFERRER_URL',
            newValue: window.location.href
        })
      },
      setStartTime: function() {
        this.$store.commit('updateVariablesLocal', {
            variableName: 'localStartTime',
            newValue: moment(new Date()).format()
        })
      }
    },
    computed: {
      ...mapState([
        "isRtlLanguage"
      ]),
      ...mapGetters([
        "screenerStatus",
        "errorStatus",
        "errorLoadingScreener",
        "protocolGroupId",
        "sessionId",
        "isLoadingApplication",
        "getPages",
        "showModal",
        "getCurrPage",
        "getCurrPageIndex",
        "getPageCount",
        "currentModalName",
        "isLastPage"
      ]),
      debugMode: function() {
        return DEBUG_MODE;
      },
      debugLink: function() {
        return API_BASE + '/api/v1/debug/screener/' + this.sessionId + '?protocolGroupId=' + this.protocolGroupId;
      },
      screenerArchived: function() {
        return (this.screenerStatus === 'Archive');
      },
      notFound: function() {
        return this.errorStatus >= 400 && this.errorStatus < 500;
      },
      internalServerError: function() {
        return this.errorStatus >= 500;
      },
      clientError: function() {
        return this.errorStatus < 0;
      }
    },
    watch: {
      isRtlLanguage(newValue, oldValue) {
        console.log(`Updating RTL Language from ${oldValue} to ${newValue}`);

        if (newValue) {
          this.$vuetify.rtl = true
        }
        else {
          this.$vuetify.rtl = false
        }

      }
    }
}
</script>

<style>
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #EEEEEE;
}
</style>
