<template>
    <div class="screener-question-number">
        <v-text-field type="number" outlined v-model.number="numberVal" v-on:blur="onChange()" hide-details="auto"></v-text-field>
    </div>
</template>

<script>

import { mapActions } from 'vuex'

export default {
    name: 'TextQuestion',
    props: {
        element: Object,
        currentValue: Number
    },
    data: function() {
        return {
            numberVal: null
        }
    },
    mounted: function() {
        if (this.currentValue) this.numberVal = this.currentValue;
    },
    methods: {
        ...mapActions([
            'saveResponse'
        ]),
        onChange: function() {
            this.saveResponse({
                variableName: this.element.variableName,
                elementId: this.element.sfid,
                newValue: this.numberVal
            });
        }
    },
    computed: {
    }
}
</script>