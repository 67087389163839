<template>
    <div class="screener-question-birthday">
        <v-row>
            <v-col md="auto">
                <v-select
                    outlined
                    :items=months
                    :placeholder="monthPlaceholder"
                    v-model="selectedMonth"
                    v-on:change="onChange"
                    hide-details="auto">
                </v-select>
            </v-col>
            <v-col md="auto" v-if="!isMonthOnly">
                <v-select
                    outlined
                    :items=days
                    :placeholder="dayPlaceholder"
                    v-model="selectedDay"
                    v-on:change="onChange"
                    hide-details="auto"
                    :disabled="disableDay">
                </v-select>
            </v-col>
            <v-col md="auto">
                <v-select
                    outlined
                    :items=years
                    :placeholder="yearPlaceholder"
                    v-model="selectedYear"
                    v-on:change="onChange"
                    hide-details="auto">
                </v-select>
            </v-col>
        </v-row>
    </div>
</template>

<script>

import { mapActions, mapGetters } from 'vuex'

import moment from 'moment';

import { getGlobalLabel } from '../../translation/Translation';

export default {
    name: 'BirthdateQuestion',
    props: {
        element: Object,
        currentValue: String,
        currentPickerValue: String
    },
    data: function() {
        return {
            selectedDay: null,
            selectedMonth: null,
            selectedYear: null,
        }
    },
    mounted: function() {
        if (this.currentValue) {
            let dobDate = moment(this.currentValue);
            this.selectedDay = dobDate.day();
            this.selectedMonth = dobDate.month();
            this.selectedYear = dobDate.year();
        }
    },
    computed: {
        ...mapGetters(['languageCode']),
        monthPlaceholder: function() {
            return getGlobalLabel('Birthdate Month', 'Month');
        },
        dayPlaceholder: function() {
            return getGlobalLabel('Birthdate Day', 'Day');
        },
        yearPlaceholder: function() {
            return getGlobalLabel('Birthdate Year', 'Year');
        },
        isMonthOnly: function() {
            return this.element.configurationFlag && this.element.configurationFlag.includes('DOB Month Only');
        },
        months: function() {
            let result = [];
            const formatter = new Intl.DateTimeFormat(this.languageCode, { month: 'long' });

            for (let i = 0; i <= 11; i++) {
                result.push({
                    text: formatter.format(new Date(2019, i, 1)),
                    value: i
                })
            }

            return result;
        },
        years: function() {
            let result = [];

            for (let i = new Date().getFullYear(); i > 1900 ; i--) {
                result.push({
                    text: i.toString(),
                    value: i
                });
            }

            return result;
        },
        days: function() {
            let days = new Date(new Date().getFullYear(), this.selectedMonth+1, 0).getDate();
            return Array.from(Array(days), (_, i) => i + 1)
        },
        disableDay: function() {
            if(this.selectedMonth !== null){
                return false;
            }
            return true;
        }
    },
    methods: {
        ...mapActions([
            'saveResponse'
        ]),
        onChange: function() {
            if (this.isMonthOnly) {
                this.selectedDay = 1;
            }

            if (this.selectedMonth !== null && this.selectedYear !== null && this.selectedDay !== null) {
                let birthDate = new Date(this.selectedYear, this.selectedMonth, this.selectedDay, 1);
                this.saveResponse({
                    variableName: this.element.variableName,
                    elementId: this.element.sfid,
                    newValue: moment(birthDate).format('YYYY-MM-DD')
                });
            }
        }
    }
}

</script>