<template>
    <v-container>
        <v-row justify="center" >
                <div class="screener-page">
                    <ProgressBar :pages-complete="pagesComplete" :pages-remaining="pagesRemaining" />

                    <div v-for="card in page.children" :key="card.name">    
                        <Card :card-element="card" />
                    </div>

                    <NavigationButtons @navigation="handleNavigation" />

                    <v-snackbar v-model="snackbar" :timeout="timeout">
                        <GlobalLabel label-type="Validation Error" default="Please complete all required questions on this page." />
                        
                        <v-btn color="blue" text @click="snackbar = false">
                            <GlobalLabel label-type="Close Dialog" default="Close" />
                        </v-btn>
                    </v-snackbar>

                </div>

        </v-row>
    </v-container>
</template>

<script>
import ProgressBar from './ProgressBar.vue'
import Card from './Card.vue'
import NavigationButtons from './NavigationButtons.vue'

import { getQuestionsForValidation, validateQuestionValidity } from '../../ComponentUtils'
import { mapGetters, mapMutations } from 'vuex'

export default {
    name: 'ScreenerPage',
    components: {
        ProgressBar,
        Card,
        NavigationButtons
    },
    props: {
        page: Object
    },
    data: function() {
        return {
            snackbar: false,
            timeout: 2000,
            pagesComplete: 0,
            pagesRemaining: 0
        }
    },
    watch: {
        page: function(val) {
            this.sendGtmPush(val);
        }
    },
    mounted: function() {
        this.sendGtmPush(this.page);
        this.updatePageCount();
    },
    methods: {
        sendGtmPush: function(pageVal) {
            if (pageVal) {
                var dataLayer = window.dataLayer || [];
                dataLayer.push({
                    event: 'pageview',
                    page: pageVal.variableName || pageVal.name,
                    ...this.variables
                }); 
            }
        },
        ...mapMutations(['incrementPage', 'decrementPage', 'updateValidationErrors']),
        updatePageCount: function() {
            let currentIndex = this.getCurrPageIndex;
            this.pagesComplete = currentIndex + 1;

            let pageCount = this.getPageCount;
            this.pagesRemaining = (pageCount - 1) - currentIndex;
        },
        handleNavigation: function (event){
            if (event === "next") {
                if (this.validate()) {
                    //window.scrollTo(0, 0);
                    document.getElementsByClassName('v-progress-linear')[0].scrollIntoView();
                    this.incrementPage();
                }
                else {
                    this.snackbar = true;
                }
            }
            //"prev"
            else if (event === 'prev') {
                this.decrementPage();
            }

            this.updatePageCount();
        },
        validate: function() {
            let questionsForValidation = getQuestionsForValidation(this.page.children);
            
            let isValid = true;
            if (questionsForValidation.length > 0) {
                // validateQuestionValidity will return is all questions on the page are valid or not, and will inside the function update the state with the validation messages.
                const elementsWithError = validateQuestionValidity(questionsForValidation, { validateTableRadio: true });
                if (elementsWithError.length > 0) {
                    isValid = false;
                    this.updateValidationErrors(elementsWithError);
                }


            }
            

            return isValid;
        }
    },
    computed: {
        ...mapGetters(['getCurrPageIndex', 'getPageCount', 'variables'])
    }
}
</script>

<style scoped>
    .screener-page {
        margin: 1em;
        padding: 1em;
        width: 1024px;
        max-width: 100%;
    }
</style>