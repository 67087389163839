<template>
    <div class="screener-question-text">
        <template v-if="maskValue">
            <!-- Mask values are only supported on short text fields --> 
            <v-text-field required outlined v-model="textVal" v-on:blur="onChange()" hide-details="auto" :placeholder="maskValue" v-mask="maskValue"></v-text-field>
        </template>
        <template v-else>
            <template v-if="isLongText">
                <v-textarea
                    outlined
                    auto-grow
                    v-model="textVal"
                    v-on:blur="onChange()"
                ></v-textarea>
            </template>
            <template v-else>
                <!-- For non-mask short text fields, we need to explicitly declare it without the v-mask attribute, otherwise an error will be thrown -->
                <v-text-field required outlined v-model="textVal" v-on:blur="onChange()" hide-details="auto"></v-text-field>
            </template>
        </template>
        
    </div>
</template>

<script>

import { mapActions, mapGetters } from 'vuex'

export default {
    name: 'TextQuestion',
    props: {
        element: Object,
        currentValue: String
    },
    data: function() {
        return {
            textVal: null
        }
    },
    mounted: function() {
        if (this.currentValue) this.textVal = this.currentValue;
    },
    methods: {
        ...mapActions([
            'saveResponse'
        ]),
        onChange: function() {
            this.saveResponse({
                variableName: this.element.variableName,
                elementId: this.element.sfid,
                newValue: this.textVal
            });
        },
    },
    computed: {
        ...mapGetters([
            'currentCountryConfiguration'
        ]),
        isLongText: function() {
            return this.element.configurationFlag && this.element.configurationFlag === 'Multi-Line Text';
        },
        maskValue: function() {
            if (this.element.textFormat && this.currentCountryConfiguration) {
                if (this.element.textFormat === 'Phone') {
                    return this.currentCountryConfiguration.country.phoneNumberFormat;
                }
                if (this.element.textFormat === 'Postal Code') {
                    return this.currentCountryConfiguration.country.postalCodeFormat;
                }
            }
            return undefined;
        }
    }
}
</script>