<template>
    <div class="screener-question-picklist">
        <v-select
            outlined
            :items=selectOptions
            :placeholder="placeholderText"
            v-model="selectedItem"
            v-on:change="onChange"
            hide-details="auto">
        </v-select>
        <div v-if="isOther">
            <v-text-field required label="If other, please specify" v-model="otherVal" v-on:change="onChange"></v-text-field>
        </div>
    </div>
</template>

<script>

import { mapActions } from 'vuex'
import { getTranslatedText, getGlobalLabel } from '../../translation/Translation';

export default {
    name: 'PicklistQuestion',
    props: {
        element: Object,
        currentValue: String,
    },
    data: function() {
        return {
            selectedItem: null,
            otherVal: null
        }
    },
    mounted: function() {
        if (this.currentValue) this.selectedItem = this.currentValue;
    },
    computed: {
        placeholderText: function() {
            return getGlobalLabel('Picklist Placeholder', 'Select Option...');
        },
        selectOptions: function() {
            let result = [];

            if (this.element && this.element.options) {
                for (const option of this.element.options) {
                    result.push({
                        value: option.value,
                        text: getTranslatedText(option.labels, option.name, true),
                        sequence: option.sequence
                    })
                }
            }

            result.sort((a,b) => 
                (a.sequence && b.sequence) ? a.sequence - b.sequence : 0
            )

            return result;
        },
        isOther: function(){
            if(this.selectedItem!==null){
                if(this.selectedItem.toUpperCase()==="OTHER"){
                    return true;
                }
            }
            return false;
        }
    },
    methods: {
        ...mapActions([
            'saveResponse'
        ]),
        onChange: function() {
            if(this.isOther && this.otherVal!==null){
                this.saveResponse({
                    variableName: this.element.variableName,
                    elementId: this.element.sfid,
                    newValue: "Other/"+this.otherVal
                });
            }else if(!this.isOther){
                this.otherVal=null;
                this.saveResponse({
                    variableName: this.element.variableName,
                    elementId: this.element.sfid,
                    newValue: this.selectedItem
                });
            }else{
                this.saveResponse({
                    variableName: this.element.variableName,
                    elementId: this.element.sfid,
                    newValue: null
                });
            }
        }
    }
}

</script>