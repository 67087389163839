import store from '../store/index';


function getElementByName(elementName, searchList) {
    if (searchList) {
        for (const element of searchList) {
            if (element.name === elementName) {
                return element;
            }
            if (element.children) {
                let childResult = getElementByName(elementName, element.children);
                if (childResult) return childResult;
            }
        }
    }

    return null;
}

function getVisibilityDictionary(elementList) {
    const pageListClone = JSON.parse(JSON.stringify(elementList));

    populateVisibilityOnElements(pageListClone);
    
    return getElementDictionary(pageListClone);
}

function populateVisibilityOnElements(elementList, forceHidden = false) {
    for (const element of elementList) {
        if (forceHidden) {
            element.isVisible = false;
            if (element.children) {
                populateVisibilityOnElements(element.children, forceHidden);
            }
        }
        else {
            const isVisible = shouldRender(element);
            element.isVisible = isVisible;
            if (element.children) {
                populateVisibilityOnElements(element.children, !isVisible);
            }
        }
    }
}

function clearHiddenComponentValues(preVisibility, postVisibility, depth = 0) {
    
    if (depth > 5) {
        // Looks like we are in some kind of recursive condition or the conditional tree is just too complex. Let's shut this down before it turns into an infinite loop.
        console.error('[WARNING] Max depth exceeded for clearHiddenComponentValues: ' + depth);
        return;
    }

    const removeValuesList = [];

    for (const elementId in preVisibility) {
        const element = postVisibility[elementId];
        const wasVisible = preVisibility[elementId].isVisible;
        const isNowVisble = postVisibility[elementId].isVisible;

        if (wasVisible && !isNowVisble && element.type === 'Question') {
            removeValuesList.push(postVisibility[elementId]);
        }
    }

    if (removeValuesList.length > 0) {
        for (const element of removeValuesList) {
            store.commit('updateResponseLocal', {
                variableName: element.variableName,
                elementId: element.sfid,
                newValue: null
            });
        }

        store.dispatch('updateCalculatedVariables');

        // We had to remove some values, so evaluate the conditions again because some of the hidden values we just cleared may affect downstream visibility for nested logic
        const newVisibilityDictionary = getVisibilityDictionary(store.getters.getPages);

        clearHiddenComponentValues(postVisibility, newVisibilityDictionary, depth + 1);
    }
    
}

// Recursively search the search list and all children
// Return a dictionary of Element SFID -> Elements
function getElementDictionary(searchList) {
    let result = {};

    for (const row of searchList) {
        result[row.sfid] = row;
        if (row.children) {
            result = Object.assign(result, getElementDictionary(row.children));
        }
    }

    return result;
}

// Get all *visible* required questions on the given elements list. 
// Will recursively search for children to handle scenarios like cards and nested question types like radio groups
function getQuestionsForValidation(searchList) {
    let result = [];

    if (searchList) {
        for (const row of searchList) {
            if (shouldRender(row)) {
                if ((row.type === 'Question' || row.type === 'Site Selection') && (row.required || row.textFormat)) {
                    result.push(row);
                }
                if (row.children) {
                    let childResults = getQuestionsForValidation(row.children);
                    result = result.concat(childResults);
                }
            }
        }
    }

    return result;
}


function validateQuestionValidity(requiredQuestionsList, options = {}) {
    const errors = [];

    const responses = store.getters.responses;

    for (const question of requiredQuestionsList) {
        let questionIsValid = true;
        const value = responses[question.sfid]?.value;

        if (question.required) {    
            // The options is to account for a special case with 
            if (question.questionType === 'TableRadio') { 
                if (options && options.validateTableRadio) {
                    for (const childQuestion of question.children) {
                        const childValue = responses[childQuestion.sfid]?.value;
                        if (!childValue) {
                            questionIsValid = false;
                        }
                    }
                }
            }
            else {
                if (value == undefined || value == null || value === '' || (typeof value === "string" && value.trim() === '')) {
                    questionIsValid = false;
                }
            }
            
        }
        if (question.textFormat && value !== undefined && value !== null && value !== '') {
            if (question.textFormat === 'Email' && !validateEmail(value)) {
                questionIsValid = false;
            }
            else if (question.textFormat === 'Phone' && !validatePhone(value)) {
                questionIsValid = false;
            }
            else if (question.textFormat === 'Postal Code' && !validatePostalCode(value)) {
                questionIsValid = false;
            }
            else if (question.textFormat === 'Regular Expression') {
                const regex = RegExp(question.formula);
                questionIsValid = regex.test(value);
            }
        }   

        if (!questionIsValid) {
            errors.push(question.sfid);
        }
    }

    return errors;
}

function validateEmail(email)  {

    let re = /[A-Z0-9._%+-]+@[A-Z0-9.-]+.[A-Z]{2,4}/igm;
    return re.test(email);  
}

function validatePhone(number) {
    if (number.length === (store.getters.currentCountryConfiguration.country.phoneNumberFormat).length){
        return true;
    }
    return false
}

function validatePostalCode(code) {
    if (code.length === (store.getters.currentCountryConfiguration.country.postalCodeFormat).length){
        return true;
    }
    return false
}


function shouldRender(element) {
    let result = true;

    // Create two local variables to allow rule "formulas" to access and reference them.
    let responses = Object.assign({}, store.getters.responsesByVariableName); //eslint-disable-line
    let variables = Object.assign({}, store.getters.variables); //eslint-disable-line

    if (element && element.rules) {
        // If every rule is satisfied, we should render this element, otherwise it should not be rendered.
        result = element.rules.every(row => {
            return eval(row.formula);
        });
    }
    
    return result;
}

export {
    getElementByName,
    getQuestionsForValidation,
    shouldRender,
    validateQuestionValidity,
    getElementDictionary,
    getVisibilityDictionary,
    clearHiddenComponentValues,
    validatePhone,
    validatePostalCode
};