import Vue from 'vue'
import Vuex from 'vuex'

import getters from './getters'
import mutations from './mutations'
import actions from './actions'
import { v4 as uuidv4 } from 'uuid'

Vue.use(Vuex)

//import { DEFAULT_LANGUAGE } from '../components/translation/Translation';

export default new Vuex.Store({
  state: {
    loadingApplication: true,
    //language: DEFAULT_LANGUAGE,
    responses: {},
    variables: {},
    computedVariables: [],
    screenerLanguages: [],
    sessionId: uuidv4(),
    protocolGroupId: null,
    screenerId: null,
    currPage: 0,
    currentModalName: null,
    pageList: [],
    globalLabels: [],
    elementDictionary: {}, // Map of Element ID -> Element
    countryConfigurations: [],
    validationErrors: [], // Array of Element SF IDs that were found to have validation errors
    errorLoadingScreener: false,
    errorStatus: 0,
    screenerStatus: null,
    leadStatus: 'In Progress',
    selectedSiteRecord: {},
    isRtlLanguage: false
  },
  getters,
  mutations,
  actions
})
