import { saveScreenerResponses } from '../network/api';
import { getScreenerDefinition } from '../network/api';

import { getVisibilityDictionary, clearHiddenComponentValues } from '../components/ComponentUtils';

import moment from 'moment';
import * as Sentry from '@sentry/browser'

export default {
    async updateProtocolGroupId({ commit, state, dispatch }, data) {
        if (state.protocolGroupId !== data) {
            commit('updateProtocolGroup', data);

            let response = null;
            try {
                response = await getScreenerDefinition(data);
            }
            catch (error) {
                commit('setErrorLoadingScreener', true);

                if (error.response) {
                    console.log(error.response.data);
                    console.log(error.response.status);

                    commit('setErrorStatus', error.response.status);
                }
                else {
                    commit('setErrorStatus', -1);
                    Sentry.captureException(error);
                }
            }

            commit('updateLoadingApplication', false);

            if (response !== null) {
                let screener = response.data;

                commit('setPages', screener.pages)
                commit('setComputedVariables', screener.variables);
                commit('setGlobalLabels', screener.globalLabels);
                commit('updateScreenerId', screener.screenerId);
                commit('updateScreenerStatus', screener.screenerStatus);
                commit('updateCountryConfigurations', screener.countryConfigurations);
                commit('updateScreenerLanguages', screener.screenerLanguages);

                if (screener.gtmContainerId) {
                    try {
                        (function (w, d, s, l, i) {
                            w[l] = w[l] || []; w[l].push({
                                'gtm.start':
                                    new Date().getTime(), event: 'gtm.js'
                            }); var f = d.getElementsByTagName(s)[0],
                                j = d.createElement(s), dl = l != 'dataLayer' ? '&l=' + l : ''; j.async = true; j.src =
                                    'https://www.googletagmanager.com/gtm.js?id=' + i + dl; f.parentNode.insertBefore(j, f);
                        })(window, document, 'script', 'dataLayer', screener.gtmContainerId);
                    }
                    catch (err) {
                        console.error('Error while initializing GTM', err);
                    }
                }

                dispatch('updateScreenerLanguageValues');

                dispatch('updateCalculatedVariables');
            }
        }
    },
    async saveResponse({ commit, state, getters, dispatch }, data) { // eslint-disable-line
        commit('updateVariablesLocal', {
            variableName: 'localEndTime',
            newValue: moment(new Date()).format()
        })

        // Get a dictionary of all elements and their visibility before the change is made
        const visibilityDictionaryPrevious = getVisibilityDictionary(state.pageList);

        if (Array.isArray(data)) {
            for (const row of data) {
                commit('updateResponseLocal', row);

                commit('updateVariablesLocal', {
                    variableName: 'lastQuestionAnswered',
                    newValue: row.variableName
                });
            }
        }
        else {
            commit('updateResponseLocal', data);

            commit('updateVariablesLocal', {
                variableName: 'lastQuestionAnswered',
                newValue: data.variableName
            });
        }

        dispatch('updateCalculatedVariables');

        // Get a dictionary of all elements and their visibility AFTER the change is made
        const visibilityDictionaryPost = getVisibilityDictionary(state.pageList);

        // For any elements that were visible previously, but now are not, clear out the associated value
        clearHiddenComponentValues(visibilityDictionaryPrevious, visibilityDictionaryPost, 0);

        await saveScreenerResponses(state.screenerId, state.sessionId, getters.leadStatus, state.responses, state.variables);
    },
    async triggerSave({ state, getters }) {
        await saveScreenerResponses(state.screenerId, state.sessionId, getters.leadStatus, state.responses, state.variables);
    },
    async updateLeadStatus({ commit, state, getters }, data) {
        if (state.leadStatus != data) {
            commit('updateLeadStatus', data);
            await saveScreenerResponses(state.screenerId, state.sessionId, getters.leadStatus, state.responses, state.variables);
        }
    },
    updateCalculatedVariables({ commit, dispatch, state, getters }) {
        if (state.computedVariables) {
            let responses = getters.responsesByVariableName; //eslint-disable-line
            let variables = getters.variables; //eslint-disable-line

            let screenerLanguageCalculated = false;

            state.computedVariables.forEach(row => {
                try {
                    let result = eval(row.formula);
                    commit('updateVariablesLocal', {
                        variableName: row.variableName,
                        newValue: result
                    });

                    if (row.variableName == 'language') {
                        screenerLanguageCalculated = true;
                    }
                }
                catch (err) {
                    console.error('Error while computing variable ' + row.name + ': ' + err);
                }
            });

            if (screenerLanguageCalculated) {
                // If there is a calculated variable that could change the language from what was originally passed in the URL,
                // re-run the screener language values logic that updates the Language Name, Code and ID in the screener variables.
                dispatch('updateScreenerLanguageValues');
            }

        }
    },
    updateScreenerLanguageValues({ commit, getters }) {
        const screenerLanguages = getters.screenerLanguages;
        if (screenerLanguages) {
            let language = getters.currentLanguage;
            for (const screenerLanguage of screenerLanguages) {
                if (screenerLanguage.language === language || (screenerLanguage.language === 'English' && language === 'English-US')) {
                    commit('updateVariablesLocal', {
                        variableName: 'screenerLanguageName',
                        newValue: screenerLanguage.language
                    })

                    commit('updateVariablesLocal', {
                        variableName: 'screenerLanguageCode',
                        newValue: screenerLanguage.languageCode
                    })

                    commit('updateVariablesLocal', {
                        variableName: 'screenerLanguageId',
                        newValue: screenerLanguage.sfid
                    })

                    if (screenerLanguage.rightToLeft) {
                        console.log('RTL Language enabled');
                        commit('updateRtlStatus', true);
                    }
                }
            }
        }
    }
}
