<template>
    <div class="screener-question-radio">

        <div>
            <v-radio-group v-model="value" v-on:change="onChange" column hide-details="auto">
                <div v-for="item in radioOptions" :key="item.value">
                    <v-radio :label=item.label :value=item.value></v-radio>
                </div>
            </v-radio-group>
        </div>

        <div v-if="isOther">
            <div>
                <v-text-field outlined v-model="otherVal" v-on:blur="onChange()"></v-text-field>
            </div>
        </div>

    </div>
</template>

<script>

import { mapActions } from 'vuex'
import { getTranslatedText } from '../../translation/Translation';

export default {
    name: 'RadioQuestion',
    props: {
        element: Object,
        currentValue: String
    },
    data: function() {
        return {
            value: '',
            otherVal: null
        }
    },
    mounted: function() {
        if (this.currentValue) this.value = this.currentValue;
    },
    computed: {
        radioOptions: function() {
            let result = [];

            if (this.element && this.element.options) {
                for (const option of this.element.options) {
                    result.push({
                        value: option.value,
                        label: getTranslatedText(option.labels, option.name, true),
                        sequence: option.sequence
                    })
                }
            }

            result.sort((a,b) => 
                (a.sequence && b.sequence) ? a.sequence - b.sequence : 0
            )

            return result;
        },
        isOther: function(){
            if(this.value.toUpperCase()==="OTHER"){
                return true;
            }
            return false;
        }
    },
    methods: {
        ...mapActions([
            'saveResponse'
        ]),
        onChange: function() {
            if(this.isOther && this.otherVal !== null){
                this.saveResponse({
                    variableName: this.element.variableName,
                    elementId: this.element.sfid,
                    newValue: "Other/"+this.otherVal
                });
            }else if(!this.isOther){
                this.otherVal=null
                this.saveResponse({
                    variableName: this.element.variableName,
                    elementId: this.element.sfid,
                    newValue: this.value
                });
            }else{
                this.saveResponse({
                    variableName: this.element.variableName,
                    elementId: this.element.sfid,
                    newValue: null
                });
            }
        }
    }
}
</script>

<style scoped>
.v-input--selection-controls {
    margin-top: 0.5em !important;
    padding-top: 0 !important;
}
</style>