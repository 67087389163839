<template>  
    <div class="screener-card">
        <v-card class="mx-auto" raised v-resize="onResize">
                <v-container fluid>
                    <!-- wrapper row -->
                    <v-row align="center" justify="center" no-gutters>
                        <!-- left margin/logo space -->
                        <v-col cols="2">
                        </v-col>
                        <!-- prog header wrapper -->
                        <v-col>
                            <div v-if="isMobile == true">
                                <!-- extra col to match spacing of segmented bar -->
                                <v-col>
                                    <v-progress-linear id="i" :value="percentage" buffer-value="100" height="10">
                                    </v-progress-linear>
                                </v-col>
                            </div>
                            <div v-else>
                                <v-row>
                                    <template v-for="i in pagesComplete">
                                        <v-col :key="i + 'complete'">
                                            <div>
                                                <v-progress-linear id="i" value="100" buffer-value="100" height="10">
                                                </v-progress-linear>
                                            </div>
                                        </v-col>
                                    </template>
                                    <template v-for="j in pagesRemaining">
                                        <v-col :key="j + 'remaining'">
                                            <div>
                                                <v-progress-linear id="j" value="0" buffer-value="100" height="10">
                                                </v-progress-linear>
                                            </div>
                                        </v-col>
                                    </template>
                                </v-row>
                            </div>
                        </v-col>
                        <!-- right margin/percentage -->
                        <v-col cols="2">
                            <div style="text-align: center;">
                                <b>{{percentage}}%</b>
                            </div>
                        </v-col>
                    </v-row>
                </v-container>
        </v-card>
    </div>

</template>

<script>

export default {
    name: 'ProgressBar',
    data: function() {
        return {
            windowWidth: 0
        }
    },
    props: {
        pagesComplete: Number,
        pagesRemaining: Number
    },
    mounted: function() {
        this.currPageIndex = this.$store.getters.getCurrPageIndex+1;
        this.pageCount = this.$store.getters.getPageCount;

        this.onResize();
    },
    methods: {
        onResize(){
            this.windowWidth = window.innerWidth;
        }
    },
    computed: {
        percentage: function() {
            return Math.floor(this.pagesComplete/(this.pagesComplete+this.pagesRemaining)*100)
        },
        isMobile: function() {
            return this.windowWidth < 600
        }
    }
}
</script>