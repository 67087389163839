import { getElementDictionary, validateQuestionValidity } from '../components/ComponentUtils';

export default {
  updateLoadingApplication(state, data) {
    state.loadingApplication = data;
  },
  // updateLanguage(state, data) {
  //   state.language = data;
  // },
  updateProtocolGroup(state, data) {
    state.protocolGroupId = data;
  },
  updateScreenerId(state, data) {
    state.screenerId = data;
  },
  updateScreenerStatus(state, data) {
    state.screenerStatus = data;
  },
  updateCountryConfigurations(state, data) {
    state.countryConfigurations = data;
    this.commit('updateCountryCode');
  },
  updateScreenerLanguages(state, data) {
    state.screenerLanguages = data;
  },
  updateRtlStatus(state, data) {
    state.isRtlLanguage = data;
  },
  updateResponseLocal(state, data) {
    const variableName = data.variableName;
    const elementId = data.elementId;
    const newValue = data.newValue;

    let responses = Object.assign({}, state.responses);

    if (!(elementId in responses) && newValue == null) {
      // We don't have a value for this yet, no need to set a null value.
      return;
    }

    responses[elementId] = {
      variableName: variableName,
      value: newValue,
      responseTime: new Date()
    }

    state.responses = responses;

    // Check for any validation errors and update the state if so
    const element = state.elementDictionary[elementId];
    const newValidationErrors = validateQuestionValidity([element]);
    if (state.validationErrors && state.validationErrors.includes(elementId)) {
      // We currently have a validation error the this question
      if (newValidationErrors.length == 0) {
        // The error no longer exists, so clear it out from the global state
        state.validationErrors = state.validationErrors.filter(p => p !== elementId);
      }
      else {
        // Global validation state already includes this question and the results have not changed, no action required.
      }
    }
    else {
      // Merge any existing validation errors with the validation results of this current entry
      state.validationErrors = state.validationErrors.concat(newValidationErrors);
    }
  },
  updateVariablesLocal(state, data) {
    let variables = Object.assign({}, state.variables); // Make copy of the variables so that we can assign the whole object back and trigger an update message
    variables[data.variableName] = data.newValue;
    state.variables = variables;

    if (data.variableName === 'country' && state.countryConfigurations) {
      this.commit('updateCountryCode');
    }
  },
  incrementPage(state) {
    state.currPage = state.currPage + 1;
  },
  decrementPage(state) {
    state.currPage = state.currPage - 1;
  },
  setPages(state, elements) {
    state.pageList = elements;
    state.elementDictionary = getElementDictionary(elements);
  },
  setComputedVariables(state, data) {
    state.computedVariables = data;
  },
  showModal(state, data) {
    state.currentModalName = data.modalName;
  },
  closeModal(state) {
    state.currentModalName = null;
  },
  updateValidationErrors(state, data) {
    state.validationErrors = data;
  },
  removeElementFromValidationErrors(state, elementId) {
    if (state.validationErrors && state.validationErrors.includes(elementId)) {
      const newValidationErrors = state.validationErrors.filter(row => row !== elementId);
      state.validationErrors = newValidationErrors;
    }
  },
  setGlobalLabels(state, data) {
    state.globalLabels = data;
  },
  setStartTime(state, data) {
    state.startTime = data;
  },
  setEndTime(state, data) {
    state.startTime = data;
  },
  setErrorLoadingScreener(state, data) {
    state.errorLoadingScreener = data;
  },
  setErrorStatus(state, data) {
    state.errorStatus = data;
  },
  updateCountryCode(state) {
    if (state.countryConfigurations) {
      let country = this.getters.country;
      for (var i = 0; i < state.countryConfigurations.length; i++) {
        if (state.countryConfigurations[i].country.name === country) {
          let code = state.countryConfigurations[i].country.countryCode;
          this.commit('updateVariablesLocal', {
            variableName: 'countryCode',
            newValue: code
          })
        }
      }
    }
  },
  updateLeadStatus(state, data) {
    state.leadStatus = data;
  },
  updateSelectedSite(state, data) {
    state.selectedSiteRecord = data;
  }
}
