import axios from 'axios';



const API_BASE = process.env.VUE_APP_API_BASE;

function isPreview() {
    return window.location.search && window.location.search.includes('preview');
}

function getUrlParamOrNull(paramName) {
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.has(paramName)) {
        return urlParams.get(paramName);
    }
    return null;
}

function getConfig() {
    let config = {
        params: {}
    };
    if (isPreview()) {
        config.params.preview = true;
    }
    return config;
}

async function getScreenerDefinition(protocolGroupId) {
    const config = getConfig();
    const screenerId = getUrlParamOrNull('screenerId');
    const formType = getUrlParamOrNull('formType');
    if (screenerId != null) {
        config.params.screenerId = screenerId;
    }
    if (formType != null) {
        config.params.formType = formType;
    }

    let response = await axios.get(API_BASE + '/api/v1/screener/' + protocolGroupId, config);
    return response;
}

async function saveScreenerResponses(screenerId, sessionId, status, responses, variables) {
    let responsesForServer = Object.keys(responses).map(elementId => {
        return {
            elementId: elementId,
            variableName: responses[elementId].variableName,
            value: responses[elementId].value,
            responseTime: responses[elementId].responseTime
        }
    })

    let request = {
        sessionId: sessionId,
        responses: responsesForServer,
        status: status,
        variables: variables,
        screenerId: screenerId,
        clientSaveTime: new Date().toISOString()
    };

    let response = await axios.post(API_BASE + '/api/v1/screener/save', request, getConfig());

    return response.data;
}

//Site map
//fire from inside onSearch in Map
async function getSites(groupId, screenerId, areaCode, country) {//"85018"
    let request = {
        protocolGroupId: groupId,
        screenerId: screenerId,
        postalCode: areaCode,
        country: country
    };

    let response = await axios.post(API_BASE + '/api/v1/searchsites', request, getConfig());
    return response.data;
}



export {
    getSites, getScreenerDefinition, saveScreenerResponses, API_BASE
};
