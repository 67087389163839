import store from '../../store/index';

const DEFAULT_LANGUAGE = 'English-US';
const SECONDARY_DEFAULT_LANGUAGE = 'English';

function getGlobalLabel(labelType, defaultValue, removeParagraphs = true) {
    const globalLabels = store.getters.globalLabels;
    let labels = globalLabels.filter(p => p.globalLabelType === labelType);
    return getTranslatedText(labels, defaultValue, removeParagraphs);
}

function getTranslatedText(labels, defaultValue, removeParagraphs = false) {
    let text = null;

    let currentLanguage = store.getters.currentLanguage;
    const language = currentLanguage ? currentLanguage : DEFAULT_LANGUAGE;

    if (labels) {
       // debugger; // eslint-disable-line
        const translated = labels.find(val => val.languageCode === language);
        const defaultLanguageTranslated = labels.find(val => val.languageCode === DEFAULT_LANGUAGE || val.languageCode === SECONDARY_DEFAULT_LANGUAGE);
        if (translated) {
            text = translated.value;   
        }
        else if (defaultLanguageTranslated) {
            text = defaultLanguageTranslated.value;
        }
    }
    if (!text) {
        text = defaultValue;
    }

    if (text && removeParagraphs) {
        text = text.replace(/(<p[^>]+?>|<p>|<\/p>)/img, "");
    }

    return text;
}

export { getTranslatedText, getGlobalLabel, DEFAULT_LANGUAGE }