import { shouldRender } from '../components/ComponentUtils';

import { DEFAULT_LANGUAGE } from '../components/translation/Translation';

function getVisiblePageList(pages) {
  let pageList = pages;
  let result = [];

  for (const page of pageList) {
    if (shouldRender(page)) {
      result.push(page);
    }
  }

  return result;
}

const DEFAULT_COUNTRY = 'USA';
const DEFAULT_LANGUAGE_CODE = 'en';

export default {
  protocolGroupId(state) {
    return state.protocolGroupId;
  },
  isLoadingApplication(state) {
    return state.loadingApplication
  },
  screenerId(state) {
    return state.screenerId;
  },
  screenerStatus(state) {
    return state.screenerStatus;
  },
  screenerLanguages(state) {
    return state.screenerLanguages;
  },
  errorLoadingScreener(state) {
    return state.errorLoadingScreener;
  },
  languageCode(state) {
    if (state.variables && 'screenerLanguageCode' in state.variables && state.variables.screenerLanguageCode) {
      return state.variables.screenerLanguageCode;
    }
    return DEFAULT_LANGUAGE_CODE;
  },
  isRtlLanguage(state) {
    return state.isRtlLanguage;
  },
  siteSelectionZip(state) {
    if (state.variables && 'SITE_SELECTION_ZIP' in state.variables) {
      return state.variables.SITE_SELECTION_ZIP;
    }
    return undefined;
  },
  country(state) {
    if (state.variables && 'country' in state.variables) {
      return state.variables.country;
    }
    return DEFAULT_COUNTRY;
  },
  sessionId(state) {
    return state.sessionId;
  },
  currentLanguage(state) {
    // return state.language;
    if (state.variables && 'language' in state.variables) {
      return state.variables.language;
    }
    return DEFAULT_LANGUAGE;
  },
  responses(state) {
    return state.responses;
  },
  responsesByVariableName(state) {
    let result = {};

    for (const elementId of Object.keys(state.responses)) {
      let response = state.responses[elementId];
      if (response.value) result[response.variableName] = response.value;
    }

    return result;
  },
  variables(state) {
    return state.variables;
  },
  getPages(state) {
    return state.pageList;
  },
  getVisiblePages(state) {
    return getVisiblePageList(state.pageList);
  },
  globalLabels(state) {
    return state.globalLabels;
  },
  showModal(state) {
    return state.currentModalName != null;
  },
  currentModalName(state) {
    return state.currentModalName;
  },
  getCurrPage(state) {
    let pages = getVisiblePageList(state.pageList);
    return pages[state.currPage];
  },
  getCurrPageIndex(state) {
    return state.currPage;
  },
  getPageCount(state) {
    let pages = getVisiblePageList(state.pageList);
    return pages.length;
  },
  isFirstPage(state) {
    return (0 === state.currPage);
  },
  isLastPage(state) {
    let pages = getVisiblePageList(state.pageList);
    return (pages.length - 1 === state.currPage);
  },
  currentCountryConfiguration(state, getters) {
    if (state.countryConfigurations && state.countryConfigurations.length > 0) {
      let currentConfig = state.countryConfigurations.find(p => p.country.name === getters.country);
      return currentConfig;
    }

    return null;
  },
  validationErrors(state) {
    return state.validationErrors;
  },
  errorStatus(state) {
    return state.errorStatus;
  },
  leadStatus(state) {
    return state.leadStatus;
  },
  selectedSiteRecord(state) {
    return state.selectedSiteRecord;
  }
}
