<template>
  <v-row justify="center">
    <v-dialog scrollable persistent v-model="dialog" max-width="500">
      <v-card>
        <v-card-title>
          <TranslatedText :labels="cardElement.labels" :default="cardElement.name"  />
        </v-card-title>
         <v-divider></v-divider>
        <v-card-text>
          <div class="cardContent">
            <div v-for="element in cardElement.children" :key="element.name">
                <div v-if="element.type === 'Display'" class="screener-card-item">
                    <Label :element="element" />
                </div>
                <div v-if="element.type === 'Question'" class="screener-card-item">
                    <Question :element="element" />
                </div>
              </div>
          </div>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="closeDialog">
            <GlobalLabel label-type="Close Dialog" default="Close" />
          </v-btn>
        </v-card-actions>
      </v-card> 
    </v-dialog>
  </v-row>
</template>

<script>

import Label from './Label'
import Question from '../question/Question'

import { getElementByName } from '../../ComponentUtils';
import { mapGetters } from 'vuex';

export default {
    name: 'CtmDialog',
    components: {
      Label,
      Question
    },
    props: {
        dialogName: String
    },
    data() {
        return {
          dialog: true
        }
    },
    methods: {
      closeDialog: function() {
        this.$store.commit('closeModal');
      }
    },
    computed: {
      ...mapGetters(['getPages']),
      cardElement: function() {
        let component = getElementByName(this.dialogName, this.getPages);
        return component;
      }
    }
}
</script>

<style scoped>
  .cardContent {
    max-height: 400px;
    padding: 1rem;
  }
</style>