<template>
    <div class="screener-question-radiogroup">

            <!-- pulled from sample data -->
            <div v-for="child in element.children" :key="child.sfid" class="radiogroup-row">
                <div v-if="isMobile === false">
                    <v-row justify="center" align="center" no-gutters>
                        <v-col sm="4" md="3" lg="2" style="margin-right: 1rem">
                            <TranslatedText :labels="child.labels" :default="child.name" remove-paragraphs />
                        </v-col>
                        <v-col cols="auto" class="mr-auto">
                            <v-radio-group v-model="values[child.sfid]" @change="onChange()" row hide-details="auto">
                                <div v-for="choice in radioOptions" :key="choice.value">
                                    <v-radio :label="choice.label" :value="choice.value"
                                    ></v-radio>
                                </div>
                            </v-radio-group>
                        </v-col>
                        <v-col>
                            <div v-if="'Other'===values[child.sfid]" style="width: 180px">
                                <v-text-field required v-model="other[child.sfid]" v-on:blur="onChange()"></v-text-field>
                            </div>
                        </v-col>
                    </v-row>
                </div>
                <div v-else>
                    <v-row justify="start" align="center" no-gutters>
                        <v-col sm="4" md="3" lg="2" style="margin-right: 1rem">
                            <TranslatedText :labels="child.labels" :default="child.name" remove-paragraphs />
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="auto" class="mr-auto">
                            <v-radio-group v-model="values[child.sfid]" @change="onChange()" row hide-details="auto">
                                <div v-for="choice in radioOptions" :key="choice.value">
                                    <v-radio :label="choice.label" :value="choice.value"
                                    ></v-radio>
                                </div>
                            </v-radio-group>
                        </v-col>
                        <v-col>
                            <div v-if="'Other'===values[child.sfid]" style="width: 180px">
                                <v-text-field required v-model="other[child.sfid]" v-on:blur="onChange()"></v-text-field>
                            </div>
                        </v-col>
                    </v-row>
                </div>
            </div>

    </div>
</template>

<script>

import { mapActions, mapGetters } from 'vuex'
import { getTranslatedText } from '../../translation/Translation';

export default {
    name: 'RadioGroupQuestion',
    props: {
        element: Object
    },
    data: function() {
        return {
            values: {},
            other: {},
            windowWidth: 0
        }
    },
    mounted: function() {
        let responses = this.responses;
        let newValues = {};
        for (const childQuestion of this.element.children) {
            if (childQuestion.sfid in responses) {
                newValues[childQuestion.sfid] = responses[childQuestion.sfid].value;
            }    
        }
        this.values = newValues;
        this.onResize();
    },
    computed: {
        ...mapGetters(['responses']),
        radioOptions: function() {
            let result = [];

            if (this.element && this.element.options) {
                const sorted = [...this.element.options].sort((a,b) => 
                    (a.sequence && b.sequence) ? a.sequence - b.sequence : 0
                )

                for (const option of sorted) {
                    result.push({
                        value: option.value,
                        label: getTranslatedText(option.labels, option.name, true),
                        sequence: option.sequence
                    })
                }
            }

            return result;
        },
        isMobile: function() {
            return this.windowWidth < 600
        }
    },
    methods: { 
        ...mapActions([
            'saveResponse'
        ]),
        onResize(){
            this.windowWidth = window.innerWidth;
        },
        onChange: function() {
            let newValues = [];

            newValues.push({
                variableName: this.element.variableName,
                elementId: this.element.sfid,
                newValue: '-'
            });

            for (const elementId in this.values){
                let child = this.element.children.find(row => row.sfid == elementId);
                if(this.values[elementId]==="Other" && this.other[elementId]!==void(0)){
                    newValues.push({
                        variableName: child.variableName,
                        elementId: elementId,
                        newValue: "Other/"+this.other[elementId]
                    });
                //shouldn't be able to save "Other" with null "other" text
                }else if(this.values[elementId]!=="Other"){
                    //clear "Other" val from array
                    this.other[elementId]=void(0)
                    newValues.push({
                        variableName: child.variableName,
                        elementId: elementId,
                        newValue: this.values[elementId]
                    });
                }else{
                    //"other" text input is empty
                }
            }
            this.saveResponse(newValues);
            
        }
    }
}
</script>

<style scoped>
.v-input--selection-controls {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
}
.radiogroup-row {
    padding: 0.25em;
}
.radiogroup-row:nth-child(even) {
    background-color: #eee;
}
</style>